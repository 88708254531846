import { DocumentMeta } from './meta';
import type { Media, MovieCurrency, PaymentStatus, Territory } from './static/types';

interface IncomeExtraData { [key: string]: number }

export interface Income {
  _meta?: DocumentMeta;
  id: string;
  /** TermId linked to this income */
  termId: string;
  /** The contract that created this income */
  contractId: string;
  price: number;
  version: Record<string, { hidden?: true, price: number }>;
  currency?: MovieCurrency;
  offerId?: string;
  titleId?: string;
  status: PaymentStatus;
  date: Date;
  medias: Media[]; // For waterfall purposes, optional
  territories: Territory[]; // For waterfall purposes, optional
  sourceId: string; // Should be defined for waterfall purposes
  order: number; // For waterfall purposes
  extra?: IncomeExtraData; // For waterfall purposes
  licensee?: string; // For waterfall purposes
}

export function createIncome(params: Partial<Income> = {}): Income {
  return {
    id: '',
    termId: '',
    contractId: '',
    price: 0,
    version: {},
    status: 'pending',
    date: new Date(),
    medias: [],
    territories: [],
    sourceId: '',
    order: 0,
    ...params,
  };
}

/**
 * Return incomes with the same contractId, medias and territories
 * @param subject 
 * @param incomes 
 */
export function getMatchingIncomes(subject: Income, incomes: Income[]): Income[] {
  return incomes.filter(p => {
    if (p.contractId !== subject.contractId) return false;
    if (!!subject.licensee && p.licensee !== subject.licensee) return false;
    const sameMedias = p.medias.every(m => subject.medias.includes(m)) && p.medias.length === subject.medias.length;
    const sameTerritories = p.territories.every(t => subject.territories.includes(t)) && p.territories.length === subject.territories.length;
    return sameMedias && sameTerritories;
  });
}

/**
 * Add missing extra data attributes to current income with previously existing extra data
 * @param subject 
 * @param incomes 
 */
export function addMissingExtraData(subject: Income, incomes: Income[]) {
  const matching = getMatchingIncomes(subject, incomes);
  const extraKeys: string[] = [];
  for (const m of matching) {
    const keys = Object.keys(m.extra || {});
    if (keys.length > 0) extraKeys.push(...keys);
  }
  for (const key of Array.from(new Set(extraKeys))) {
    if (!subject.extra) subject.extra = {};
    if (!subject.extra[key]) subject.extra[key] = 0;
  }
  return subject;
}

export function getSourceNotHiddenIncomes(incomes: Income[], sourceId: string, versionId: string) {
  const sourceIncomes = incomes.filter(i => i.sourceId === sourceId);
  return sourceIncomes.filter(i => !i.version[versionId] || i.version[versionId].hidden !== true);
}
